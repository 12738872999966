import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import * as React from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useIntl } from "../../plugins/gatsby-plugin-intl-with-root"
import { navigate } from "../../plugins/gatsby-plugin-intl-with-root/link"
import * as Illust from "../assets/illust"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MEMBERSHIP, MODAL } from "../constants"
import { membershipState, modalState, userState } from "../store"
import { guideBg, oneDecimalPlace, PriceCardTitle, sizeBg } from "../utils"

const PricingPage = () => {
  const theme = useTheme()
  const sharedProps = { theme }
  const setModal = useSetRecoilState(modalState)
  const setMembership = useSetRecoilState(membershipState)
  const user = useRecoilValue(userState)
  const intl = useIntl()

  return (
    <System.Block
      overrides={{
        Block: {
          padding: `0 ${theme.sizing.scale3200}`,
          [theme.mediaQuery.large]: {
            padding: 0,
          },
        },
      }}
    >
      <System.Block
        maxWidth={`${theme.breakpoints.large}px`}
        margin="0 auto"
        backgroundColor={guideBg}
        overrides={{
          Block: {
            [theme.mediaQuery.large]: {
              padding: `0 ${theme.sizing.scale800}`,
            },
          },
        }}
      >
        <System.Spacing height="scale1600" backgroundColor={sizeBg} />
        <System.DisplayXSmall>Pricing</System.DisplayXSmall>
        <System.Spacing height="scale1200" backgroundColor={sizeBg} />
        <System.Block
          display="grid"
          gridGap={theme.sizing.scale1200}
          overrides={{
            Block: {
              gridTemplateColumns: "repeat(3, 1fr)",
              [theme.mediaQuery.large]: {
                gridTemplateColumns: "1fr",
              },
            },
          }}
        >
          <System.Card
            label={intl.formatMessage({ id: "pages.pricing.free" })}
            title="$0"
            onClick={() =>
              user.email ? navigate("/courses") : setModal(MODAL.SIGN_IN)
            }
          >
            <System.CardBody>
              {intl.formatMessage({ id: "pages.pricing.free_description" })}
            </System.CardBody>
            <System.Spacing height="scale700" backgroundColor={sizeBg} />
            <Illust.Abstract1 />
            <System.CardAction>
              <System.Button
                kind="tertiary"
                startEnhancer={
                  <System.Icons.Arrow
                    width={theme.sizing.scale300}
                    color={theme.colors.primary}
                  />
                }
              >
                {intl.formatMessage({
                  id: user.email ? "ui.cta.enter_course" : "ui.cta.sign_up",
                })}
              </System.Button>
            </System.CardAction>
          </System.Card>

          <System.Card
            label={intl.formatMessage({
              id: "pages.pricing.membership.yearly.title",
            })}
            title={
              <PriceCardTitle
                price={`$${oneDecimalPlace(
                  MEMBERSHIP.yearly.price / 100 / 12
                )}`}
                salePrice={`$${oneDecimalPlace(
                  MEMBERSHIP.yearly.sale_price / 100 / 12
                )}/${intl.formatMessage({
                  id: "ui.price.month",
                })}`}
              />
            }
            onClick={() => {
              setMembership(MEMBERSHIP.yearly)
              setModal(MODAL.BECOME_A_MEMBER)
            }}
          >
            <System.CardBody>
              {intl.formatMessage({
                id: "pages.pricing.membership.yearly.excerpt",
              })}
            </System.CardBody>
            <System.Spacing height="scale700" backgroundColor={sizeBg} />
            <Illust.Abstract2 />
            <System.CardAction>
              <System.Button
                kind="tertiary"
                startEnhancer={
                  <System.Icons.Arrow
                    width={theme.sizing.scale300}
                    color={theme.colors.primary}
                  />
                }
              >
                {intl.formatMessage({ id: "ui.cta.subscribe" })}
              </System.Button>
            </System.CardAction>
          </System.Card>

          <System.Card
            label={intl.formatMessage({
              id: "pages.pricing.membership.monthly.title",
            })}
            title={
              <PriceCardTitle
                price={`$${oneDecimalPlace(MEMBERSHIP.monthly.price / 100)}`}
                salePrice={`$${oneDecimalPlace(
                  MEMBERSHIP.monthly.sale_price / 100
                )}/${intl.formatMessage({
                  id: "ui.price.month",
                })}`}
              />
            }
            onClick={() => {
              setMembership(MEMBERSHIP.monthly)
              setModal(MODAL.BECOME_A_MEMBER)
            }}
          >
            <System.CardBody>
              {intl.formatMessage({
                id: "pages.pricing.membership.monthly.excerpt",
              })}
            </System.CardBody>
            <System.Spacing height="scale700" backgroundColor={sizeBg} />
            <Illust.Abstract2 />
            <System.CardAction>
              <System.Button
                kind="tertiary"
                startEnhancer={
                  <System.Icons.Arrow
                    width={theme.sizing.scale300}
                    color={theme.colors.primary}
                  />
                }
              >
                {intl.formatMessage({ id: "ui.cta.subscribe" })}
              </System.Button>
            </System.CardAction>
          </System.Card>

          {process.env.PRODUCTION !== "true" && (
            <System.Card
              label="Member (Monthly)"
              title={`결제 테스트 ($${MEMBERSHIP.test.price / 100})`}
              onClick={() => {
                setMembership(MEMBERSHIP.test)
                setModal(MODAL.BECOME_A_MEMBER)
              }}
            >
              <System.CardBody>TEST</System.CardBody>
              <System.Spacing height="scale700" backgroundColor={sizeBg} />
              <Illust.Abstract2 />
              <System.CardAction>
                <System.Button
                  kind="tertiary"
                  startEnhancer={
                    <System.Icons.Arrow
                      width={theme.sizing.scale300}
                      color={theme.colors.primary}
                    />
                  }
                >
                  Subscribe
                </System.Button>
              </System.CardAction>
            </System.Card>
          )}
        </System.Block>
        <System.Spacing height="scale1600" backgroundColor={sizeBg} />
      </System.Block>
    </System.Block>
  )
}

export default () => (
  <Layout>
    <SEO title="Pricing" />
    <PricingPage />
  </Layout>
)
